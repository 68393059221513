import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import ReactMarkdown from 'react-markdown';
import { t } from 'bv-i18n';
import Labels from 'Offers/components/labels';
import { WagerProgress } from 'SharedComponents/step';
import WalletsButton from 'Offers/components/wallet/wallets_button';
import { toCurrency } from 'bv-helpers/number';

const { showDefaultMessage } = window.ModalHelper;

export const showTCs = (terms) => () => {
  showDefaultMessage({
    children: <ReactMarkdown source={terms} softBreak="br" />,
    icon: false,
    iconClosable: true,
    className: 'is-left page-contents',
  });
};

const WalletInfo = ({ displayTerms, wallet }) => (
  <div>
    <div className="offers-card__image" style={{ backgroundImage: `url(${wallet.image})` }} />
    <div className="wallets-card__balance">{toCurrency(wallet.balance.toFixed(2))}</div>
    <Labels expiration={wallet.expiration} />
    <div className="wallets-card__title">{wallet.name}</div>
    <div className="wallets-card__description">{wallet.description}</div>
    { wallet.wager && <WagerProgress {...wallet.wager} phrase="wager_required" /> }
    <WalletsButton {...wallet} />
    <div className="wallets-card__footer">
      {displayTerms
        && (
        <Link to={`/offer/wallets/${wallet.refNumber}`}>
          <div className="wallets-card__terms">{t('javascript.promo_hub.t_and_cs')}</div>
        </Link>
        )}
      <div className="wallets-card__ref">{`${t('javascript.promo_hub.ref')} ${wallet.refNumber}`}</div>
    </div>
  </div>
);

WalletInfo.propTypes = {
  wallet: {
    image: PropTypes.string.isRequired,
    expiration: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    refNumber: PropTypes.string.isRequired,
    termsAndConditions: PropTypes.string.isRequired,
    wager: PropTypes.instanceOf(Object).isRequired,
  },
  displayTerms: PropTypes.bool.isRequired,
  displayExpiration: PropTypes.bool.isRequired,
};

WalletInfo.defaultProps = {
  wallet: {},
};

export default WalletInfo;
