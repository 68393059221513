import PropTypes from 'prop-types';
import classnames from 'classnames';
import { locale } from 'bv-i18n';

import withOutcome from 'sportsbook-with-outcome';
import { formatPrice } from 'sportsbook-helpers';

const StepOutcome = ({
  outcome, selected, enhancedOutcome, toggleBetslip,
}) => (
  <div className="enhanced-odds__offers">
    <div className="enhanced-odds__offers--description">
      <strong>{outcome.eventDescription}</strong>
      <br />
      <small>{new Date(outcome.eventStartTime).toLocaleString(locale(), { dateStyle: 'long', timeStyle: 'short' })}</small>
      <br />
      <small>
        {outcome.marketDescription}
        {' '}
        -
        {' '}
        {outcome.periodDescription}
      </small>
    </div>
    { enhancedOutcome?.active
        && (
        <div className="enhanced-odds__offers--button">
          <button onClick={toggleBetslip} className={classnames('bvs-button-sport outcome-button', { active: selected })} type="button">
            <div className="outcome-n-wrapper bet">
              <span className="outcome-n-description">
                {outcome.outcomeDescription}
              </span>
              <div className="outcome-n-wrapper bet">
                <span className="outcome-enhanced-odds">
                  <span className="bvs-event-icon is-bet-boost" />
                  {formatPrice(enhancedOutcome.fractionalEnhancedOdds)}
                </span>
                <span className="outcome-previous-enhanced-odds">
                  {formatPrice(outcome.priceCustomPriceFormat)}
                </span>
              </div>
            </div>
          </button>
        </div>
        )}
  </div>
);

StepOutcome.propTypes = {
  toggleBetslip: PropTypes.isRequired,
  selected: PropTypes.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  enhancedOutcome: PropTypes.instanceOf(Object).isRequired,
};

export default withOutcome(StepOutcome);
